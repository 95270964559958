import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material';
import { MatCardModule } from '@angular/material/card';
import { FormWrapperComponent } from './form-wrapper.component';
import { LazyModule } from '../lazy.module';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatDividerModule,
    LazyModule
  ],
  exports: [FormWrapperComponent],
  declarations: [FormWrapperComponent]
})
export class FormWrapperModule { }
