import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as _ from 'lodash';

const URL_REGEX = 'https?://.+';
const MAX_SAFE_NUMBER = 2147483647;
const ZERO = 0;

/**
 * @description Validates if given control has white spaces
 * @param {AbstractControl} control Abstract form control to validate
 * @returns {ValidationErrors} Form validation errors
 */
export const noWhitespaceValidator = function (control: AbstractControl): ValidationErrors {
  // the field is no required if field is empty then no error
  if (_.isEmpty(_.toString(control.value))) {
    return null;
  }
  const isWhitespace = _.toString(control.value).trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : {whiteSpace: '', INVALID: true};
};

/**
 * @description Validates if given control is a valid URL regex
 * @param {AbstractControl} control Abstract form control to validate
 * @returns {ValidationErrors} Form validation errors
 */
export const urlValidator = function (control: AbstractControl): ValidationErrors {
  const urlRegex = new RegExp(URL_REGEX);
  const regextest = urlRegex.test(control.value);

  return regextest ? null : {INVALID: true};
};

export const alphanumericValidator = function (control: AbstractControl): ValidationErrors {
  const alphanumericRegex = /^([a-zA-Z0-9]+$)/;
  return alphanumericRegex.test(control.value) ? null : {alphanumeric: 'this field only accepts alphanumeric characters'};
};

export const integerOrEmptyValidator = function (control: AbstractControl): ValidationErrors {
  const integerRegex = /^-?\d+$/gm;
  const value = _.toString(control.value);
  if (_.isEqual(_.size(_.trim(value)), ZERO)) {
    return null;
  }
  if (!integerRegex.test(value)) {
    return {integer: 'this field only accepts integer values'};
  }
  if (control.value > Number.MAX_SAFE_INTEGER) {
    return {integerOverflow: 'this field must not be greater than ' + Number.MAX_SAFE_INTEGER};
  }
  return null;
};

export const decimalOrEmptyValidator = function (control: AbstractControl): ValidationErrors {
  const regexDecimal = /^-?\d+([,\.]\d+)?$/gm;
  const value = _.toString(control.value);
  if (_.isEqual(_.size(_.trim(value)), ZERO)) {
    return null;
  }
  // a number only keeps 17 decimal places of precision
  // if value has more that 17 decimal then is invalid number
  if (value.replace('-', '').replace('.', '').length > 15) {
    return {decimalOverflow: 'this field only admits a maximum of 15 digits'};
  }
  if (!regexDecimal.test(value)) {
    return {decimal: 'this field only accepts numbers'};
  }
  return null;
};

export const mustNotIncludeWhiteSpaces = function (control: AbstractControl): ValidationErrors {
  const WHITE_SPACE = ' ';
  const username = _.toString(control.value);
  if (_.isEmpty(username)) {
    return null;
  }

  return username.includes(WHITE_SPACE)
    ? {mustNotIncludeWhiteSpaces: 'this field must not include white spaces'} : null;
};

/**
 * @description Is integer validator.
 * @param {AbstractControl} control - Control
 */
export const isInteger = function (control: AbstractControl): ValidationErrors {
  const integerRegex = /^-?\d+$/gm;
  const value = _.toString(control.value);
  if (!integerRegex.test(value)) {
    return {integer: 'this field only accepts integer values'};
  }
  if (control.value > MAX_SAFE_NUMBER) {
    return {integerOverflow: 'this field must not be greater than ' + MAX_SAFE_NUMBER};
  }
  return null;
};

/**
 * @description Is Positive validator
 * @param {AbstractControl} control - Control
 */
export const isPositiveOrZero = function (control: AbstractControl): ValidationErrors {
  if (_.isNil(control.value)) {
    return null;
  }
  if (_.isNumber(control.value) && _.toNumber(control.value) >= ZERO) {
    return null;
  } else if (control.value < ZERO) {
    return {positive: 'this field only accepts positive values'};
  }
};
