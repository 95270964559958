import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-wrapper',
  styleUrls: ['./../../app.component.css', './form-wrapper.component.css'],
  templateUrl: './form-wrapper.component.html'
})
export class FormWrapperComponent {
  @Input() formTitle?: string;
  @Input() formInfo?: string;
}
