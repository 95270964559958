import { RolePermission } from './role-permission';
import { Module, Permission } from './module';
import { User } from './user';

export class Role {
  public static adminRoleName = 'Admin';
  public created?: Date;
  public description?: string;
  public id?: number;
  public modules?: Module[];
  public name: string;
  public updated: Date;
  public updatedBy?: User;
  public permissions: Permission[];
  public rolePermissions: RolePermission[];

  /**
   * @description returns an object mapping the corresponding translation label for the role object properties
   * @return {object} object with labels
   */
  public static getColumnDefinitions (): object {
    return {
      name: 'ROLE.LABELS.ROLE',
      description: 'ROLE.LABELS.DESCRIPTION',
    };
  }

  public static getErros(): object {
    return {
      default: 'ROLE.ERRORS.INVALID',
      maxLength: 'ROLE.ERRORS.MAX_LENGTH',
      noWhiteSpaces: 'ROLE.ERRORS.NO_WHITE_SPACES',
      required: 'ROLE.ERRORS.REQUIRED',
      roleName: 'ROLE.ERRORS.ROLE_NAME',
      invalidForm: 'INVALID',
    };
  }

  /**
   * @description returns an object mapping the corresponding translation label for each of the properties
   * of the object role
   * @return {object} object with labels
   */
  public static getLabels (): object {
    return {
      accept: 'COMMON.LABELS.ACCEPT',
      adminRoleDescription: 'ROLE.LABELS.ADMIN_ROLE_DESCRIPTION',
      cancel: 'MENU.ACTIONS.CANCEL',
      checkboxSelectAll: 'ROLE.LABELS.CHECKBOX_SELECT_ALL',
      confirmDelete: 'ROLE.LABELS.CONFIRM_DELETE',
      create: 'ROLE.LABELS.CREATE',
      created: 'COMMON.LABELS.CREATED',
      delete: 'ROLE.LABELS.DELETE',
      details: 'ROLE.LABELS.DETAILS',
      description: 'ROLE.LABELS.DESCRIPTION',
      menu: 'ROLE.LABELS.MENU',
      modules: 'ROLE.LABELS.MODULES',
      modulesContent: 'ROLE.LABELS.SCF_CARD_ROLES.MODULES.',
      modulesTitle: 'ROLE.LABELS.SCF_CARD_ROLES.COLUMNS.MODULES',
      name: 'ROLE.LABELS.NAME',
      permissionsContent: 'ROLE.LABELS.SCF_CARD_ROLES.PERMISSIONS.',
      permissionsTitle: 'ROLE.LABELS.SCF_CARD_ROLES.COLUMNS.PERMISSIONS',
      permissions: {
        create: 'ROLE.PERMISSIONS.CREATE',
        delete: 'ROLE.PERMISSIONS.DELETE',
        read: 'ROLE.PERMISSIONS.READ',
        title: 'ROLE.PERMISSIONS.TITLE',
        update: 'ROLE.PERMISSIONS.UPDATE',
      },
      save: 'GENERAL.SAVE',
      submodulesContent: 'ROLE.LABELS.SCF_CARD_ROLES.SUBMODULES.',
      submodulesTitle: 'ROLE.LABELS.SCF_CARD_ROLES.COLUMNS.SUBMODULES',
      title: 'ROLE.LABELS.TITLE',
      titleCardRoles: 'ROLE.LABELS.TITLE_CARD_ROLES',
      update: 'ROLE.LABELS.UPDATE',
      updated: 'COMMON.LABELS.UPDATED',
      updatedBy: 'COMMON.LABELS.UPDATED_BY',
      yes: 'MENU.ACTIONS.YES',
    };
  }

  /**
   * @description return a list of labels for notification messages
   * @return {object} object with labels
   */
   public static getMessages(): object {
    return {
      createSuccess: 'ROLE.MESSAGES.CREATE_SUCCESS',
      deleteConfirm: 'ROLE.MESSAGES.DELETE_CONFIRM',
      deleteSuccess: 'ROLE.MESSAGES.DELETE_SUCCESS',
      forbiddenDeleteAdminRole: 'ROLE.MESSAGES.FORBIDDEN_DELETE_ROLE_ADMIN',
      forbiddenDeleteRoleWithUsers: 'ROLE.MESSAGES.FORBIDDEN_DELETE_ROLE_WITH_USERS',
      forbiddenEditAdminRole: 'ROLE.MESSAGES.FORBIDDEN_EDIT_ROLE_ADMIN',
      notEmpty: 'ROLE.MESSAGES.NOT_EMPTY',
      notFound: 'ROLE.MESSAGES.NOT_FOUND',
      updateSuccess: 'ROLE.MESSAGES.UPDATE_SUCCESS'
    };
  }
}
