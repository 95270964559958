import { HttpErrorResponse } from '@angular/common/http';
import { isEqual, isNil } from 'lodash';

import { CONSTANTS } from '../shared/constants/constants';
import { Labelable } from '../models/labelable.interface';

/**
 * @description returns a translation label based on the http error object and
 * an object containing a dictionary mapping the error messages returned by the api
 * and the error messages to be displayed on the client. if the error message is not
 * found in the error dictionary provided a generic error will be returned and if the request
 * status is zero it will return a connection failure error.
 * @param { HttpErrorResponse } errorResponse object containing information about the http error
 * @param { Labelable } errorDictionary object containing the different error names and their corresponding translation label
 * @returns { string } translation label
 */
export const getErrorLabelFromHttpError = (errorResponse: HttpErrorResponse, errorDictionary: Labelable | null): string => {
  if (isEqual(errorResponse.status, CONSTANTS.NUMBER_ZERO)) {
    return CONSTANTS.ERRORS.API.OFFLINE;
  }
  if (isNil(errorResponse.error) || isNil(errorResponse.error.message) || isNil(errorDictionary)) {
    return CONSTANTS.ERRORS.API.GENERAL;
  }
  // searching error label in dictionary
  const { message } = errorResponse.error;
  const errorLabel = errorDictionary[message];
  // error name not found in dictionary
  if (isNil(errorLabel)) {
    return CONSTANTS.ERRORS.API.GENERAL;
  }
  return errorLabel;
};
